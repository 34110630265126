import { styled } from '@mui/material/styles'
import { FC } from 'react'
import {
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  ListProps,
  NumberField, TextField, useTranslate
} from 'react-admin'
import { ListActionsToolbar } from '../../components'
import { ObjectionOut } from '../../types/apiRequests'
import StatusIcon from './StatusIcon'

const ObjectionsList: FC<ListProps> = (props) => {
  const translate = useTranslate()

  return (
    <StyledList
      {...props}
      exporter={false}
      actions={
        <ListActionsToolbar i18n="resources.objections.list">
          <CreateButton variant="contained" label="resources.objections.create" />
        </ListActionsToolbar>
      }
      sort={{ field: 'created', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <FunctionField
          source="status"
          label="data.status"
          render={(record: ObjectionOut) => (
            <StatusIcon
              status={record.status}
              created={record.created}
            />
          )}
        />
        <NumberField
          source="objection_number"
          label="data.objection_number"
          emptyText="-"
        />
        <FunctionField
          source="type"
          label="data.referentieNummer"
          render={(record: ObjectionOut) => record?.object_woz_number || record?.object_reference_number || '-'}
          sortable={false}
        />
        <FunctionField
          source="type"
          label="data.objectionType"
          render={(record: ObjectionOut) => translate(`resources.objections.objectionType.${record?.type || 'UNKNOWN'}`)}
          sortable={false}
        />
        <TextField
          source="subject_name"
          label="data.subject"
          sortable={false}
        />
        <FunctionField
          source="type"
          label="data.address"
          render={(record: ObjectionOut) => (
            `${record?.object_street || '-'} ${record?.object_house_number || ''}, ${record?.object_city || ''}`
          )}
          sortable={false}
        />
        <DateField
          source="created"
          label="data.created"
        />
        <EditButton />
      </Datagrid>
    </StyledList>
  )
}

const PREFIX = 'xxllncToDosList'

const classes = {
  spacer: `${PREFIX}-spacer`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`& .${classes.spacer}`]: {
    marginLeft: theme.spacing(1)
  },
}))

export default ObjectionsList
